<template>
  <section id="email-sent">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="4">
              <b-form-group
                label="Processo"
                label-for="journey-type"
              >
                <b-form-select
                  id="journey-type"
                  v-model="filtersValues.journeyType"
                  :options="activationFlowJourneyTypesOptions"
                  :disabled="isLoading"
                />
              </b-form-group>
              <b-form-group
                label="Operador"
                label-for="thrower"
              >
                <b-form-select
                  id="thrower"
                  v-model="filtersValues.thrower"
                  :options="throwersOptions"
                  :disabled="isLoading"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="Data da operação"
                label-for="date"
              >
                <flat-pickr
                  id="date"
                  ref="datePickerDate"
                  v-model="filtersValues.date"
                  class="form-control"
                  :config="pickrConfig"
                />
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Tipo de cadastro"
                label-for="userType"
              >
                <b-form-select
                  id="userType"
                  v-model="filtersValues.userType"
                  :options="userTypesOptions"
                  :disabled="isLoading"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="form-buttons d-flex justify-content-end"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-buscar"
                  :disabled="isLoading"
                  variant="primary"
                  @click="getActivations()"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  Buscar
                </b-button>

                <b-button
                  id="btn-limpar"
                  :disabled="isLoading"
                  variant="outline-secondary"
                  @click="resetFilters()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card v-if="isSubmited">
          <b-row
            class="header-card"
            align-v="center"
          >
            <b-col>
              <h4>
                Resultados
              </h4>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-table
                :busy="isLoading"
                responsive
                :items="results"
                :fields="result_fields"
                show-empty
                no-local-sorting
              >
                <template #empty>
                  <h4 class="text-center mt-2 mb-2">
                    Nenhum resultado encontrado
                  </h4>
                </template>

                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #head(created_at)>
                  <div class="text-nowrap">
                    Data
                  </div>
                </template>

                <template #head(thrower)>
                  <div class="text-nowrap">
                    Operador
                  </div>
                </template>

                <template #head(user)>
                  <div class="text-nowrap">
                    Cadastro
                  </div>
                </template>

                <template #head(actions)>
                  <div class="text-nowrap text-center">
                    Ações
                  </div>
                </template>

                <template #cell(created_at)="row">
                  <div class="text-nowrap">
                    {{ row.item.created_at ? formatDateTimeDbToView(row.item.created_at) : '' }}
                  </div>
                </template>

                <template #cell(thrower)="row">
                  <div
                    class="d-flex align-items-start justify-content-start"
                  >
                    <b-avatar
                      size="40"
                      :src="row.item.thrower && row.item.thrower.avatar ? row.item.thrower.avatar.sm : ''"
                      variant="light-primary"
                      class="mr-1"
                    >
                      <feather-icon
                        v-if="row.item.thrower && !row.item.thrower.name"
                        icon="UserIcon"
                        size="22"
                      />
                    </b-avatar>
                    <div>
                      {{ row.item.thrower ? row.item.thrower.name : '' }}<br>
                      <b-badge
                        :variant="{
                          registration_approved: 'warning',
                          registration_reviewed: 'info',
                          registration_active_reviewed: 'dark',
                          user_status_blocked: 'secondary',
                        }[row.item.type]"
                      >
                        {{ activationFlowJourneyTypesOptions.find(f => f.value === row.item.type).text }}
                      </b-badge>
                    </div>
                  </div>
                </template>

                <template #cell(user)="row">
                  <div
                    class="d-flex align-items-start justify-content-start"
                  >
                    <b-avatar
                      size="40"
                      :src="row.item.user && row.item.user.avatar ? row.item.user.avatar.sm : ''"
                      variant="light-primary"
                      class="mr-1"
                    >
                      <feather-icon
                        v-if="row.item.user && !row.item.user.name"
                        icon="UserIcon"
                        size="22"
                      />
                    </b-avatar>
                    <div>
                      <b-badge variant="dark">
                        ({{ row.item.user ? row.item.user.id : '' }})
                      </b-badge>
                      {{ row.item.user ? row.item.user.name : '' }}
                      <!-- eslint-disable vue/no-v-html -->
                      <div v-html="row.item.description.replaceAll('\n', '<br>')" />
                      <!-- eslint-enable vue/no-v-html -->
                      <div v-if="!Number.isInteger(row.item.user.doublesCount)">
                        <b-spinner small />
                        Procurando duplos
                      </div>
                      <div v-else>
                        <b-badge
                          :variant="row.item.user.doublesCount > 0 ? 'danger' : 'success'"
                        >
                          {{ row.item.user.doublesCount }} {{ row.item.user.doublesCount === 1 ? 'possível duplo' : 'possíveis duplos' }}
                        </b-badge>
                      </div>
                    </div>
                  </div>
                </template>

                <template #cell(actions)="row">
                  <div class="text-nowrap text-center">
                    <b-button
                      variant="flat-primary"
                      :to="{ name: 'cms-user-form-update', params: { id: row.item.user.id } }"
                      title="Ver destinatário"
                      target="_blank"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                    <b-button
                      variant="flat-primary"
                      :to="{ name: 'cms-user-double-registration', params: { id: row.item.user.id } }"
                      title="Ver duplos"
                      target="_blank"
                    >
                      <feather-icon
                        icon="UsersIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row
            v-if="!isLoading"
          >
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                :link-gen="() => '#'"
                align="end"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BBadge,
  BRow,
  BButton,
  BFormGroup,
  BFormSelect,
  BCol,
  BSpinner,
  BBreadcrumb,
  BCard,
  BTable,
  BPaginationNav,
  BAvatar,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import JourneyService from '@/services/journeyService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  userTypes,
  activationFlowJourneyTypesOptions, managersOptions, withEmptyOptionFirst,
} from '@/utils/options'
import {
  formatDateTimeDbToView,
  removeKnownDomains,
} from '@/utils/helpers'
import UserService from '@/services/userService'

export default {
  components: {
    BBadge,
    BRow,
    BButton,
    BFormGroup,
    BFormSelect,
    BCol,
    BSpinner,
    BBreadcrumb,
    BCard,
    BTable,
    BPaginationNav,
    BAvatar,
    BCardActions,
    flatPickr,
  },
  data() {
    const defaultFilterValues = {
      userType: '',
      journeyType: '',
      date: '',
      thrower: '',
    }
    return {
      managers: [],
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Relatórios',
          active: true,
        },
        {
          text: 'Revisão de Ativações',
          active: true,
        },
      ],
      pickrConfig: {
        mode: 'range',
        allowInput: false,
        position: 'auto center',
        dateFormat: 'Y-m-d',
        altFormat: 'd/m',
        altInput: true,
        locale: Portuguese,
      },
      isLoading: false,
      userTypesOptions: withEmptyOptionFirst(userTypes),
      activationFlowJourneyTypesOptions: withEmptyOptionFirst(activationFlowJourneyTypesOptions),
      defaultFilterValues,
      filtersValues: {
        ...defaultFilterValues,
      },
      pages: {},
      currentPage: 1,
      results: [],
      isSubmited: false,
      formatDateTimeDbToView,
      isProcessingQueue: false,

      throwersOptions: withEmptyOptionFirst(managersOptions),
    }
  },
  computed: {
    result_fields() {
      return [
        { key: 'created_at', sortable: false, thStyle: 'width: 120px' },
        { key: 'thrower', sortable: false },
        { key: 'user', sortable: false },
        { key: 'actions', sortable: false, thStyle: 'width: 120px' },
      ]
    },
    resultsToCheckForDoubles() {
      return this.results.filter(f => !Number.isInteger(f.user.doublesCount))
    },
  },
  watch: {
    currentPage() {
      this.getActivations()
    },

    results() {
      if (this.isProcessingQueue || this.resultsToCheckForDoubles.length < 1) return

      this.isProcessingQueue = true

      const { user } = this.resultsToCheckForDoubles[0]

      let phones = [user.cell_phone ?? '', ...user.phones]
      const ddds = [...new Set(phones.map(m => {
        const match = m.replace(/[^0-9]*/g, '')
        if (match.length > 1) {
          return match.substr(0, 2)
        }
        return ''
      }).filter(f => f.trim() !== ''))]

      phones = phones.map(m => {
        const match = m.replace(/[^0-9]*/g, '')
        return match.substr(2)
      }).filter(f => f.trim() !== '')

      const data = {
        user_type: user.type,
        email: user.all_emails.split(',').map(removeKnownDomains),
        ddd: ddds,
        phones,
        page: 1,
      }
      this.userService.getDoubleRegistrations(data).then(response => {
        this.isProcessingQueue = false
        this.setDoublesCount(user.id, response.data.data.users.total - 1)
      })
    },
  },
  async created() {
    this.userService = new UserService()
    this.managers = await this.userService.getAndStoreManagers()
  },
  methods: {
    setDoublesCount(userId, doublesCount) {
      this.results = [...this.results.map(journey => ({
        ...journey,
        user: {
          ...journey.user,
          doublesCount: journey.user.id === userId ? doublesCount : journey.user.doublesCount,
        },
      }))]
    },
    clearResults() {
      this.results = []
      this.isSubmited = false
      this.pages = {}
    },
    resetFilters() {
      this.filtersValues = {
        ...this.defaultFilterValues,
      }

      this.clearResults()
    },
    getActivations() {
      if (this.isLoading) {
        return false
      }

      this.isLoading = true
      this.isSubmited = true

      const formData = { ...this.filtersValues }

      if (formData.date.includes(' até ')) {
        [formData.dateFrom, formData.dateTo] = formData.date.split(' até ')
      } else {
        formData.dateFrom = formData.date
      }

      JourneyService.listActivations(formData, this.currentPage)
        .then(response => {
          if (response.data !== null) {
            const { data, ...pages } = response.data.data.activations
            this.results = [...data.map(m => ({
              ...m,
              thrower: this.managers.find(f => f.id === m.thrower_user_id),
            }))]

            if (this.results.length === 0) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ops!',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: 'Nenhum resultado encontrado com os termos pesquisados',
                },
              })
            }

            this.pages = pages
          }

          this.isLoading = false
        })

      return true
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
